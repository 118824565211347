.sectioncareer{
    background-color: #3E3E3E;
    /* border-bottom-left-radius: 2vw; */
    /* border-bottom-right-radius: 2vw; */
}

.positionborder{
    border-radius: 1vw;
    border: 2px solid white ;
}

.careersection2{
    background: linear-gr    adient(90deg, #E1E2E4 0%, #8D9194 100%);
    height: 90vh;
    width: 100%;
}

.contact{
    background-color: #3E3E3E;
    border-radius: 1.5vw;
}

.transparent-input {
    background-color: transparent;
    border: 1px solid #ccc;
    color: white; 
  }
  
  .transparent-input::placeholder {
    color: #aaa;
  }

  .btn {
    background: none;
    border: none;
    cursor: pointer;
    line-height: 1.5;
    font: 700 1.2rem 'Roboto Slab', sans-serif;
    padding: 1em 2em;
    letter-spacing: 0.05rem;
    position: relative;
    box-shadow: inset 0 0 0 4px white;
    color: white;
    transition: color 0.25s 0.08333s;
  }
  
  .btn::before,
  .btn::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0;
    height: 0;
    bottom: 0;
    right: 0;
  }
  
  .btn::before {
    border-bottom-width: 4px;
    border-left-width: 4px;
    transition: border-color 0s, width 0.25s, height 0.25s;
    transition-delay: 0s, 0s, 0.25s;
  }
  
  .btn::after {
    border-top-width: 4px;
    border-right-width: 4px;
    transition: border-color 0s, width 0.25s, height 0.25s;
    transition-delay: 0s, 0.25s, 0s;
  }
  
  .btn:hover {
    color: #55d7dc;
  }
  
  .btn:hover::before,
  .btn:hover::after {
    border-color: #55d7dc;
    width: 100%;
    height: 100%;
  }
  
  .btn:focus {
    outline: 2px dotted #55d7dc;
  }
  
  .mobile{
    background-image: url("../resources/Mobile_Menu_2.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
  }