.mobile{
  background-image: url("../resources/Mobile_Menu_2.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.maindiv{
    height: 100vh;
}
.scrol{
    font-family: Qerginas Frenchstyle Serif;
    font-weight: 100;
}
.hero{
    font-weight: 900;
}


h1 {
  margin: 0;
  font-size: 22px;
  line-height: 1;
  color: #423E37;
}


.page {
  /* height: 100vh; */
  /* min-height: 700px; */
  /* overflow: hidden; */
}


.tabs-controls {
  /* position: relative; */
  z-index: 10;
  /* display: flex; */
  justify-content: center;
  align-items: stretch;
  /* width: 100%; */
  /* max-width: 800px; */
  /* margin: 0 auto; */
  box-sizing: border-box;
  /* padding: 50px 0 100px; */
  list-style-type: none;
}

.tabs-controls__link {
  position: relative;
  /* display: block; */
  /* padding: 16px 32px; */
  /* font-size: 20px; */
  /* font-weight: 700; */
  color: #423E37;
  /* text-transform: uppercase; */
  text-decoration: none;

  &:after {
    position: absolute;
    left: 0;
    right: 1;
    bottom: 0;
    /* display: block; */
    /* width: 0; */
    height: 2px;
    /* background-color: #0c0902; */
    border-radius: 2px;
    margin: auto;
    content: '';
    transition: width 0.4s;
  }
}

.tabs-controls__link--active {
  &:after {
    width: 70%;
  }
}


.cards-container {
  position: relative;
  z-index: 1;
  /* width: 500px; */
  height: calc(80vh - 205px);
  /* margin: 0 auto; */
}
.card {
    /* position: absolute; */
    /* width: 500px; */
    /* height: 300px; */
    background-image: radial-gradient(#FBFBFB, #E7E7E7, #bfbfbf);
    border: 1px solid #423e3758;
    box-shadow: 9px 11px 3px 0px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    /* padding: 40px; */
  
    transition: transform 0.3s, opacity 0.2s;
  }
  
  .card.hidden {
    z-index: 100;
    background-color: #fff;
    color: #fff;
    opacity: 0;
  
    transition: color 0.5s, background-color 0.5s, transform 1s, opacity 0.2s 0.4s;
  }
  
  .card:nth-of-type(1) {
    z-index: 6;
    transform: translateX(-50px) translateY(60px);
  }
  
  .card:nth-of-type(2) {
    z-index: 5;
    transform: translateX(-40px) translateY(50px);
  }
  
  .card:nth-of-type(3) {
    z-index: 4;
    transform: translateX(-30px) translateY(40px);
  }
  
  .card:nth-of-type(4) {
    z-index: 3;
    transform: translateX(-20px) translateY(30px);
  }
  
  .card:nth-of-type(5) {
    z-index: 2;
    transform: translateX(-10px) translateY(20px);
  }
  
  .card:nth-of-type(6) {
    z-index: 1;
    transform: translateX(0px) translateY(10px);
  }
  
/* App.css */
.underline-animation {
  /* display: inline-block; */
  position: relative;
  text-decoration: none;
  color: #333; 
}

.underline-animation:before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #333; 
  transform: scaleX(0);
  transition: transform .6s ease;
}

.underline-animation:hover:before {
  transform: scaleX(1);
}

.offer {
  /* display: grid; */
  /* grid-gap: 1rem; */
  padding: 1rem;
  /* max-width: 1024px; */
  /* margin: 0 auto; */
  font-family: var(--font-sans);

  @media (min-width: bp-md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: bp-lg) {
    grid-template-columns: repeat(4, 1fr);
  }
}


.view {  
  position: relative;
  /* display: flex; */
  align-items: flex-end;
  overflow: hidden;
  padding: 1rem;
  width: 100%;
  text-align: center;
  height:100%;
  border-radius: 1vw;
  color: white;
  background-color: whitesmoke;
  box-shadow: 0 1px 1px rgba(0,0,0,0.1), 
    0 2px 2px rgba(0,0,0,0.1), 
    0 4px 4px rgba(0,0,0,0.1), 
    0 8px 8px rgba(0,0,0,0.1),
    0 16px 16px rgba(0,0,0,0.1);

  /* @media (min-width: $bp-md) {
    height: 350px;
  } */

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 110%;
    background-size: cover;
    background-position: 0 0;
    /* transition: transform calc(var(--d) * 1.5) var(--e); */
    pointer-events: none;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200%;
    pointer-events: none;
    /* background-image: linear-gradient(
      to bottom,
      hsla(0, 0%, 0%, 0) 0%,
      hsla(0, 0%, 0%, 0.009) 11.7%,
      hsla(0, 0%, 0%, 0.034) 22.1%,
      hsla(0, 0%, 0%, 0.072) 31.2%,
      hsla(0, 0%, 0%, 0.123) 39.4%,
      hsla(0, 0%, 0%, 0.182) 46.6%,
      hsla(0, 0%, 0%, 0.249) 53.1%,
      hsla(0, 0%, 0%, 0.320) 58.9%,
      hsla(0, 0%, 0%, 0.394) 64.3%,
      hsla(0, 0%, 0%, 0.468) 69.3%,
      hsla(0, 0%, 0%, 0.540) 74.1%,
      hsla(0, 0%, 0%, 0.607) 78.8%,
      hsla(0, 0%, 0%, 0.668) 83.6%,
      hsla(0, 0%, 0%, 0.721) 88.7%,
      hsla(0, 0%, 0%, 0.762) 94.1%,
      hsla(0, 0%, 0%, 0.790) 100%
    ); */
    /* background-image: url('../resources/colourful_watercolour_splatter_design_1110.jpg'); */
    background-color: #4a4a4a;
    background-repeat: no-repeat;
    /* width: 100%;
    height: 100%; */
    background-size:cover;
    /* transform: translateY(-50%); */
    /* transition: transform calc(var(--d) * 2) var(--e); */
  }

  /* @each $id in $imageIds {
    $i: index($imageIds, $id);

    &:nth-child(#{$i}):before {
      background-image: url(https://images.unsplash.com/photo-#{$id}?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ);
    }
  } */
}

.con {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 100%; */
  padding: 1rem;
  /* transition: transform var(--d) var(--e); */
  z-index: 1;

  /* > * + * {
    margin-top: 1rem;
  } */
}

.title {
  /* font-size: 1.3rem; */
  /* font-weight: bold; */
  /* line-height: 1.2; */
}

.copy {
  /* font-family: var(--font-serif); */
  font-size: 1.125rem;
  font-style: italic;
  line-height: 1.35;
}

.but {
  cursor: pointer;
  margin-top: 5.5rem;
  padding: 0.75rem 1.5rem;
  font-size: 0.65rem;
  font-weight: bold;
  letter-spacing: 0.025rem;
  text-transform: uppercase;
  color: white;
  background-color: black;
  border: none;

  /* &:hover {
    background-color: lighten(black, 5%);
  } */

  /* &:focus {
    outline: 1px dashed yellow;
    outline-offset: 3px;
  } */
}

@media (hover: hover)  {
  .view:after {
    /* transform: translateY(0); */
  }

  .con {
    /* transform: translateY(calc(100% - 4.5rem));

    > *:not(.title) {
      opacity: 0;
      transform: translateY(1rem);
      transition:
        transform var(--d) var(--e),
        opacity var(--d) var(--e);
    } */
  }

  .view:hover,
  .view:focus-within {
    /* align-items: center; */

    /* &:before { transform: translateY(-50%); } */
    /* &:after { transform: translateY(-4%); } */

    .con {
      /* transform: translateY(0); */

      /* > *:not(.title) {
        opacity: 1;
        transform: translateY(0);
        transition-delay: calc(var(--d) / 8);
      } */
    }
  }

  .view:focus-within {
    &:before,
    &:after,
    .con,
    .con > *:not(.title) {
      transition-duration: 0s;
    }
  }
}


/* .glass-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.glass {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.5s ease;
}

.glass1 {
  background-color: rgba(255, 255, 255, 0.3);
}

.glass2 {
  background-color: rgba(255, 255, 255, 0.6);
}

.glass2 {
  transform: translateY(-50%);
} */

.solution {
  display: flex;

}
.backlanding{
  background-color: #4a4a4a;
  position: sticky;
}

.circle {
  width: 100px;
  height: 100px;
  background-color: blue;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  /* margin-right: 20px; */
}

.conteeent {
  display: none;
  flex-direction: column;
  margin-right: 20px;
}

.expanded {
  display: block;
}

.foot{
  background-color: #0c0902;
}


.holderCircle { 
  width: 500px; 
  height: 500px; 
  border-radius: 100%; 
  margin: 60px auto; 
  position: relative; 
}

.dotCircle { 
  width: 100%; 
  height: 100%; 
  position: absolute; 
  margin: auto; 
  top: 0; 
  left: 0; 
  right: 0; 
  bottom: 0; 
  border-radius: 100%; 
  z-index: 20; 
}

.dotCircle .itemDot { 
  display: block; 
  width: 80px; 
  height: 80px; 
  position: absolute; 
  background: #ffffff; 
  color: #7d4ac7; 
  border-radius: 20px; 
  text-align: center; 
  line-height: 80px; 
  font-size: 30px; 
  z-index: 3; 
  cursor: pointer; 
  border: 2px solid #e6e6e6; 
}

.dotCircle .itemDot .forActive { 
  width: 56px; 
  height: 56px; 
  position: absolute; 
  top: 0; 
  left: 0; 
  right: 0; 
  bottom: 0; 
  display: none; 
}

.dotCircle .itemDot .forActive::after { 
  content: ''; 
  width: 5px; 
  height: 5px; 
  border: 3px solid #7d4ac7; 
  bottom: -31px; 
  left: -14px; 
  filter: blur(1px); 
  position: absolute; 
  border-radius: 100%; 
}

.dotCircle .itemDot .forActive::before { 
  content: ''; 
  width: 6px; 
  height: 6px; 
  filter: blur(5px); 
  top: -15px; 
  position: absolute; 
  transform: rotate(-45deg); 
  border: 6px solid #272727; 
  right: -39px; 
}

.dotCircle .itemDot.active .forActive { 
  display: block; 
}

.round { 
  position: absolute; 
  left: 40px; 
  top: 45px; 
  width: 410px; 
  height: 410px; 
  border: 2px dotted #272727; 
  border-radius: 100%; 
  -webkit-animation: rotation 100s infinite linear; 
}

.dotCircle .itemDot:hover, .dotCircle .itemDot.active { 
  color: #ffffff; 
  transition: 0.5s;   
  background: #4a4a4a; 
 background: -moz-linear-gradient(left, #a9a9a9 0%, #2e2e2e 100%); 
 background: -webkit-linear-gradient(left, #a9a9a9 0%, #2e2e2e 100%); 
 background: linear-gradient(to right, #a9a9a9 0%, #2e2e2e 100%);    
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='##a9a9a9', endColorstr='#2e2e2e', GradientType=1);  
  border: 2px solid #ffffff; 
  -webkit-box-shadow: 0 30px 30px 0 rgba(0, 0, 0, .13); 
  -moz-box-shadow: 0 30px 30px 0 rgba(0, 0, 0, .13); 
  box-shadow: 0 30px 30px 0 rgba(0, 0, 0, .13); 
  /* background: transparent; */
}

.dotCircle .itemDot { 
  font-size: 40px; 
}

.contentCircle { 
  width: 250px; 
  border-radius: 100%; 
  color: #222222; 
  position: relative; 
  top: 150px; 
  left: 50%; 
  transform: translate(-50%, -50%); 
}

.contentCircle .CirItem { 
  border-radius: 100%; 
  color: #222222; 
  position: absolute; 
  text-align: center; 
  bottom: 0; 
  left: 0; 
  opacity: 0; 
  transform: scale(0); 
  transition: 0.5s; 
  font-size: 15px; 
  width: 100%; 
  height: 100%; 
  top: 0; 
  right: 0; 
  margin: auto; 
  line-height: 250px; 
}

.CirItem.active { 
  z-index: 1; 
  opacity: 1; 
  transform: scale(1); 
  transition: 0.5s; 
}

.contentCircle .CirItem i { 
  font-size: 180px; 
  position: absolute; 
  top: 0; 
  left: 50%; 
  margin-left: -90px; 
  color: #000000; 
  opacity: 0.1; 
}

/* @media only screen and (min-width: 300px) and (max-width: 599px) {
  .holderCircle { 
    width: 300px; 
    height: 300px; 
    margin: 110px auto; 
  }

  .holderCircle::after { 
    width: 100%; 
    height: 100%; 
  }

  .dotCircle { 
    width: 100%; 
    height: 100%; 
    top: 0; 
    right: 0; 
    bottom: 0; 
    left: 0; 
    margin: auto; 
  }
} */

.title-box .title { 
  font-weight: 600; 
  letter-spacing: 2px; 
  /* position: relative;  */
  z-index: -1; 
}

.title-box span { 
  text-shadow: 0 10px 10px rgba(0, 0, 0, .15); 
  font-weight: 800; 
  color: black; 
}

.title-box p { 
  font-size: 17px; 
  line-height: 2em; 
}


.slideeer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 20px; */
  /* overflow: hidden; */
  /* width: 100%; */

  .icons-wrapper {
    /* justify-content: cente r; */
    /* margin-bottom: 20px; */
    /* overflow: hidden; */
    /* position: relative; */
    /* width: 100%; */
  }

  .icon-item {
    font-size: 2rem;
    /* margin: 0 10px; */
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 0.5;
    /* min-width: 50px; */

    &.active {
      transform: scale(1);
      opacity: 1;
    }
  }

  .icon-slide {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }

 
}


.container {
	/* position: relative; */
	transition: 1s ease-out;
	-webkit-transition: 1s ease-out;
}

.panel { 
	height: 100vh;
	width: 100%;
	background: #f7f7f7;
	position: relative;
	text-align: center;
	font: 20px/100vh 'Courier New', monospace;
}
.contactsectionlanding input{
  background: linear-gradient(90deg, #E1E2E4 0%, #8D9194 100%);
  height: 90vh;
  width: 100%;

}
.transparentinput::placeholder {
  color: #aaa;
}
input[type="text"] {
  background: transparent;
  
}
select {    
  background-color: transparent !important;
  color: #aaa !important;
}
select:focus {    
background-color: white !important;

}

.containerss {
  border-radius: 3px;
  /* width: 400px; */
  /* box-shadow: 0 5px 15px rgba(39, 30, 79, 0.3); */
}

.bar {
  margin: 0 auto;
  display: flex;
  position: relative;
  justify-content: space-between;
}

.bar:after {
  background-color: #2a2a2a44;
  content: "";
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  /* margin: auto; */
  top: 4px;
  /* width: 80%; */
  height: 1px;
}

.dot {
  color: #aaaaaa;
  /* padding: 10px ; */
  position: relative;
  text-align: center;
  transition: all 0.2s ease-in-out;
  font-weight: 600;
  z-index: 2;
}

.dot.selected {
  color: black;
}

.dot:after {
  width: 8px;
  height: 8px;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  margin: auto;
  background-color: #5C557A;
  font-size: large;
  top: 0;
  border-radius: 50%;
}

.pen-description {
  margin: 60px 0;
  text-align: center;
}


.glide__slide {
  padding: 20px 0;
}

.cardss {
  background: #fff;
  border: #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px transparent;
  display: flex;
  opacity: 0.4;
  padding: 10px 15px;
  position: relative;
  transition: all 0.2s linear;
}

.cardss .content {
  color: black;
  margin: auto;
}

.slick-slidess {
  outline: none;
}

.slick-active .cardss {
  /* box-shadow: 0 5px 15px rgba(50, 50, 50, 0.2); */
  opacity: 1;
  height: 300px;
  margin-top: -10px;
}

/* Hide next and previous buttons */
/* .slick-prev,
.slick-next {
  display: none !important;
} */

@media only screen and (max-width:768px) {
  .containerss {
    border-radius: 3px;
    /* width: 400px; */
    /* box-shadow: 0 5px 15px rgba(39, 30, 79, 0.3); */
  }
  
  .bar {
    margin: 0 auto;
    display: flex;
    position: relative;
    justify-content: space-between;
  }
  
  .bar:after {
    background-color: #2a2a2a44;
    content: "";
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    /* margin: auto; */
    top: 4px;
    /* width: 80%; */
    height: 1px;
  }
  
  .dot {
    color: #aaaaaa;
    /* padding: 10px ; */
    position: relative;
    text-align: center;
    transition: all 0.2s ease-in-out;
    font-weight: 600;
    z-index: 2;
  }
  
  .dot.selected {
    color: black;
  }
  
  .dot:after {
    width: 8px;
    height: 8px;
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    margin: auto;
    background-color: #5C557A;
    font-size: large;
    top: 0;
    border-radius: 50%;
  }
  
  .pen-description {
    margin: 60px 0;
    text-align: center;
  }
  
  
  .glide__slide {
    padding: 20px 0;
  }
  
  .cardss {
    background: #fff;
    border: #ddd;
    border-radius: 5px;
    box-shadow: 0 0 10px transparent;
    display: flex;
    opacity: 0.4;
    padding: 10px 15px;
    position: relative;
    transition: all 0.2s linear;
  }
  
  .cardss .content {
    color: black;
    margin: auto;
  }
  
  .slick-slidess {
    outline: none;
  }
  
  .slick-active .cardss {
    /* box-shadow: 0 5px 15px rgba(50, 50, 50, 0.2); */
    opacity: 1;
    height: 300px;
    margin-top: -10px;
  }
}

@media only screen and (max-width:576px){
  .containerss {
    border-radius: 3px;
    /* width: 400px; */
    /* box-shadow: 0 5px 15px rgba(39, 30, 79, 0.3); */
  }
  
  .bar {
    /* margin: 0 auto; */
    display: flex;
    position: relative;
    /* justify-con/tent: space-between; */
  }
  
  .bar:after {
    background-color: #2a2a2a44;
    content: "";
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    /* margin: auto; */
    top: 4px;
    /* width: 80%; */
    height: 1px;
  }
  
  .dot {
    color: #aaaaaa;
    /* padding: 10px ; */
    position: relative;
    text-align: center;
    transition: all 0.2s ease-in-out;
    font-weight: 600;
    z-index: 2;
  }
  
  .dot.selected {
    color: black;
  }
  
  .dot:after {
    width: 8px;
    height: 8px;
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    margin: auto;
    background-color: #5C557A;
    font-size: large;
    top: 0;
    border-radius: 50%;
  }
  
  .pen-description {
    margin: 60px 0;
    text-align: center;
  }
  
  
  .glide__slide {
    padding: 20px 0;
  }
  
  .cardss {
    background: #fff;
    border: #ddd;
    border-radius: 5px;
    box-shadow: 0 0 10px transparent;
    display: flex;
    opacity: 0.4;
    padding: 10px 15px;
    position: relative;
    transition: all 0.2s linear;
  }
  
  .cardss .content {
    color: black;
    margin: auto;
  }
  
  .slick-slidess {
    outline: none;
  }
  
  .slick-active .cardss {
    /* box-shadow: 0 5px 15px rgba(50, 50, 50, 0.2); */
    opacity: 1;
    height: 300px;
    margin-top: -10px;
  }
}
/* 
.ts-1{
  position: absolute;
  top:15%;
  left:0%

} */

.mob{
  background-image: url("../resources/Mobile0001.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}