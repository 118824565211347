.slider-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .text-container {
    margin-bottom: 20px;
  }

  .servicesphoto {
    width: 400px; 
    height: 300px; 
    overflow: hidden;
  }

  .servicesphoto   img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  button {
    margin-top: 10px;
    cursor: pointer;
  }
.serve{
    line-height: 2.3vw;
}

.mobile{
  background-image: url("../resources/Mobile_Menu_2.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}