.pageerror {
    /* width: 100%;
    height: 100%; */
    background-image: url('../resources/untitled.png');
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;  
}

.mobile{
    background-image: url("../resources/Mobile_Menu_2.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
  }